import * as i0 from '@angular/core';
import { Injectable, EventEmitter, PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject, Subject, mergeMap, takeUntil, from } from 'rxjs';
import { tsParticles } from '@tsparticles/engine';
class NgParticlesService {
  initialized = new BehaviorSubject(false);
  getInstallationStatus() {
    return this.initialized.asObservable();
  }
  async init(particlesInit) {
    await particlesInit(tsParticles);
    this.initialized.next(true);
  }
  static ɵfac = function NgParticlesService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgParticlesService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgParticlesService,
    factory: NgParticlesService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgParticlesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgxParticlesComponent {
  platformId;
  particlesService;
  options;
  url;
  id;
  particlesInit;
  particlesLoaded = new EventEmitter();
  subscription;
  destroy$ = new Subject();
  container;
  constructor(platformId, particlesService) {
    this.platformId = platformId;
    this.particlesService = particlesService;
    this.id = 'tsparticles';
  }
  ngOnInit() {
    this.subscription = this.particlesService.getInstallationStatus().subscribe(() => {
      this.container?.destroy();
      this.loadParticles();
    });
  }
  ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.loadParticles();
  }
  ngOnDestroy() {
    this.container?.destroy();
    this.subscription?.unsubscribe();
    this.destroy$.next();
  }
  loadParticles() {
    const cb = container => {
      this.container = container;
      this.particlesLoaded.emit(container);
    };
    from(this.particlesInit ? this.particlesInit(tsParticles) : Promise.resolve()).pipe(mergeMap(() => {
      return tsParticles.load({
        id: this.id,
        url: this.url,
        options: this.options
      });
    }), takeUntil(this.destroy$)).subscribe(cb);
  }
  static ɵfac = function NgxParticlesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxParticlesComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(NgParticlesService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxParticlesComponent,
    selectors: [["ngx-particles"]],
    inputs: {
      options: "options",
      url: "url",
      id: "id",
      particlesInit: "particlesInit"
    },
    outputs: {
      particlesLoaded: "particlesLoaded"
    },
    standalone: false,
    decls: 1,
    vars: 1,
    consts: [[3, "id"]],
    template: function NgxParticlesComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("id", ctx.id);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxParticlesComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-particles',
      template: '<div [id]="id"></div>'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: NgParticlesService
  }], {
    options: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    particlesInit: [{
      type: Input
    }],
    particlesLoaded: [{
      type: Output
    }]
  });
})();
class NgxParticlesModule {
  static ɵfac = function NgxParticlesModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxParticlesModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxParticlesModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [NgParticlesService]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxParticlesModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxParticlesComponent],
      exports: [NgxParticlesComponent],
      providers: [NgParticlesService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-particles
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgParticlesService, NgxParticlesComponent, NgxParticlesModule };
